import React, { useEffect, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { rem } from 'polished';
import Img from 'gatsby-image';
import { careersListing } from '../components/CareerLayout/careersListing';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import TOC from '../components/TOC';
import Perks from '../components/Perks';
import CareerLayout from '../components/CareerLayout';
import { Column, Container, Row } from '../elements/Grid';
import { LgTitle } from '../elements/Titles';
import { XsParagraph } from '../elements/Paragraphs';
import { Title } from '../elements/Careers';
import GPTW from '../images/GPTW.png';
import '../styles/careers.css';
import { UnorderedList } from '../elements/Lists';
const Careers = () => {
  const [display, setDisplay] = useState('desktop');
  const refOne = useRef(null);
  const refTwo = useRef(null);
  const refThree = useRef(null);
  const refFour = useRef(null);
  const refFive = useRef(null);
  const refSix = useRef(null);
  const refSeven = useRef(null);
  const refEight = useRef(null);
  const refNine = useRef(null);
  const refTen = useRef(null);
  const refEleven = useRef(null);
  const refTwelve = useRef(null);
  const refThirteen = useRef(null);
  const refFourteen = useRef(null);
  const refFifteen = useRef(null);
  const refSixteen = useRef(null);
  const refSeventeen = useRef(null);
  const refPerks = useRef(null);
  const jobs = [
  ];
  const scrollToRefOne = () => scrollToRef(refOne);
  const scrollToRefTwo = () => scrollToRef(refTwo);
  const scrollToRefThree = () => scrollToRef(refThree);
  const scrollToRefFour = () => scrollToRef(refFour);
  const scrollToRefFive = () => scrollToRef(refFive);
  const scrollToRefSix = () => scrollToRef(refSix);
  const scrollToRefSeven = () => scrollToRef(refSeven);
  const scrollToRefEight = () => scrollToRef(refEight);
  const scrollToRefNine = () => scrollToRef(refNine);
  const scrollToRefTen = () => scrollToRef(refTen);
  const scrollToRefEleven = () => scrollToRef(refEleven);
  const scrollToRefTwelve = () => scrollToRef(refTwelve);
  const scrollToRefThirteen = () => scrollToRef(refThirteen);
  const scrollToRefFourteen = () => scrollToRef(refFourteen);
  const scrollToRefFifteen = () => scrollToRef(refFifteen);
  const scrollToRefSixteen = () => scrollToRef(refSixteen);
  const scrollToRefSeventeen = () => scrollToRef(refSeventeen);
  const scrollToRefPerks = () => scrollToRef(refPerks);
  const refsArray = [
    scrollToRefOne,
    scrollToRefTwo,
    scrollToRefThree,
    scrollToRefFour,
    scrollToRefFive,
    scrollToRefSix,
    scrollToRefSeven,
    scrollToRefEight,
    scrollToRefNine,
    scrollToRefTen,
    scrollToRefEleven,
    scrollToRefTwelve,
    scrollToRefThirteen,
    scrollToRefFourteen,
    scrollToRefFifteen,
    scrollToRefSixteen,
    scrollToRefSeventeen
  ];

  function handleCollageLoad() {
    const script = document.createElement('script');
    script.src = 'https://assets.secure.collage.co/static/js/embed.js';
    script.async = true;

    script.onload = () => {
      console.log('Collage script has been loaded');
      if (window.collageEmbed) {
        window.collageEmbed('wise-publishing', 'en');
      }
    };

    script.onerror = () => {
      console.error('Error loading collage script');
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1192) {
        setDisplay('mobile');
      } else {
        setDisplay('desktop');
      }
    }
    window.addEventListener('resize', handleResize);
    handleCollageLoad();
  }, []);
  const scrollToRef = ref => ref.current.scrollIntoView({ behavior: 'smooth' });
  const data = useStaticQuery(graphql`
    query {
      careers: file(relativePath: { eq: "Careers.png" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      gptw: file(relativePath: { eq: "GPTW.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  const imageStyles = {
    maxWidth: rem('144px')
  };

  const imageStyles2 = {
    marginBottom: rem('32px')
  };

  const ogImageAbsoluteUrl = 'https://www.wisepublishing.com' + GPTW;

  return (
    <Layout>
      <SEO
        title="Careers"
        ogImage={ogImageAbsoluteUrl}
        ogImageHeight="785"
        ogImageWidth="1500"
      />
      <Container>
        <Row marginBottom="24">
          <Column width="5/12" className="careersBanner">
            <LgTitle marginBottom="24">At Wise Publishing</LgTitle>
            <XsParagraph className="careersCopy">
              Our goal is to help people get ahead — and that goes for both our
              readers and our employees. We believe that by supporting each
              other, and listening to everyone's ideas, we can all grow faster.
              So we strive to be open and agile, and we refuse to stifle
              creativity with a stuffy environment or tons of overtime. If that
              makes sense to you, drop us a line.
              <span onClick={scrollToRefPerks}>
                Perks of Working at Wise Publishing{' '}
                <svg
                  width="22"
                  height="15"
                  viewBox="0 0 22 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.6783 8.33601C22.0688 7.94549 22.0688 7.31232 21.6783 6.9218L15.3143 0.557838C14.9238 0.167314 14.2906 0.167314 13.9001 0.557838C13.5096 0.948363 13.5096 1.58153 13.9001 1.97205L19.557 7.62891L13.9001 13.2858C13.5096 13.6763 13.5096 14.3094 13.9001 14.7C14.2906 15.0905 14.9238 15.0905 15.3143 14.7L21.6783 8.33601ZM0.971191 8.62891H20.9712V6.62891H0.971191V8.62891Z"
                    fill="#560764"
                  />
                </svg>
              </span>
            </XsParagraph>
          </Column>
          
          <Column width="7/12">
            <Img
              fluid={data.gptw.childImageSharp.fluid}
              style={imageStyles2}
              alt="Wise Publishing is Great Place to Work certified!"
            />
          </Column>
        </Row>
        <Row
          style={{ display: 'flex', justifyContent: 'center' }}
          marginBottom="16"
        >
          <LgTitle dark="true">Open positions</LgTitle>
        </Row>

        <Row>
          <div className="careerDivider" ref={refPerks}></div>
        </Row>


        <Row marginBottom="64">
          <Column>
            <link rel='stylesheet' href='https://assets.secure.collage.co/static/style/embed.css' />
            <Row>
            <div id='collage_embed_container'></div>
            </Row>


            <Row>
              <div className="careerDivider"></div>
            </Row>


            <XsParagraph>
              Wise Publishing is an equal opportunity employer and employs
              personnel without regard to race, ancestry, place of origin,
              colour, ethnic origin, language, citizenship, creed, religion,
              gender, sexual orientation, age, marital status, physical and/or
              mental handicap or financial ability.
            </XsParagraph>
            <XsParagraph>
              Wise Publishing, Inc welcomes and encourages applications from
              people with disabilities. Accommodations are available on request
              for candidates taking part in all aspects of the selection
              process.
            </XsParagraph>
            <Row>
              <div className="careerDivider" ref={refPerks}></div>
            </Row>
          </Column>
        </Row>

        <Perks></Perks>
      </Container>
    </Layout>
  );
};

export default Careers;
