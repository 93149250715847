import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { rem } from 'polished';
import Img from 'gatsby-image';
import { Column, Container, Row } from '../../elements/Grid';
import { LgTitle } from '../../elements/Titles';
import {
  BaseParagraph,
  LgParagraph,
  XsParagraph
} from '../../elements/Paragraphs';

const WorkWithUs = () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(relativePath: { eq: "1_Health.png" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageTwo: file(relativePath: { eq: "2_Events.png" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageThree: file(relativePath: { eq: "3_Learn.png" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFour: file(relativePath: { eq: "4_Office_Extras.png" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFive: file(relativePath: { eq: "5_Happy_Hour.png" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSix: file(relativePath: { eq: "6_Work-Life_Balance.png" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  const imageStyles = {
    margin: '0 auto',
    width: rem('96px')
  };

  return (
    <Container>
      <Row marginBottom="0" textAlign="center">
        <Column>
          <LgTitle dark="true">Perks of Working at Wise Publishing</LgTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <Img
            fluid={data.imageOne.childImageSharp.fluid}
            style={imageStyles}
          />
          <LgParagraph>1.</LgParagraph>
          <BaseParagraph>Health &amp; Lifestyle Benefits</BaseParagraph>
          <XsParagraph>
            Spending card for expansive health coverage, wellness program plus 
            hybrid work environment allowing for flexibility. 
          </XsParagraph>
        </Column>
        <Column width="1/3">
          <Img
            fluid={data.imageTwo.childImageSharp.fluid}
            style={imageStyles}
          />
          <LgParagraph>2.</LgParagraph>
          <BaseParagraph>Team Outings &amp; Events</BaseParagraph>
          <XsParagraph>
            Quarterly outings (sporting events, parks, lakes, museums, tours, etc) 
            fostering fun, teamwork, industry insights and creativity.
          </XsParagraph>
        </Column>
        <Column width="1/3">
          <Img
            fluid={data.imageThree.childImageSharp.fluid}
            style={imageStyles}
          />
          <LgParagraph>3.</LgParagraph>
          <BaseParagraph>Learning &amp; Development</BaseParagraph>
          <XsParagraph>Leadership foundations, self-awareness development programs, 
            plus education funding and Lunch &amp; Learn events.</XsParagraph>
        </Column>
      </Row>
      <Row marginBottom="144">
        <Column width="1/3">
          <Img
            fluid={data.imageFour.childImageSharp.fluid}
            style={imageStyles}
          />
          <LgParagraph>4.</LgParagraph>
          <BaseParagraph>Office Extras</BaseParagraph>
          <XsParagraph>
            Snacks, espresso bar and catered lunches; outdoor patio, and plenty of lunch 
            options a short walk away.
          </XsParagraph>
        </Column>
        <Column width="1/3">
          <Img
            fluid={data.imageFive.childImageSharp.fluid}
            style={imageStyles}
          />
          <LgParagraph>5.</LgParagraph>
          <BaseParagraph>Casual, Collaborative Office</BaseParagraph>
          <XsParagraph>
            Casual dress, inviting sofas, small group chat areas, a roomy board room — all in a 
            bright environment.
          </XsParagraph>
        </Column>
        <Column width="1/3">
          <Img
            fluid={data.imageSix.childImageSharp.fluid}
            style={imageStyles}
          />
          <LgParagraph>6.</LgParagraph>
          <BaseParagraph>Work-Life Balance</BaseParagraph>
          <XsParagraph>
            Flexible work schedules and higher than average personal time off.
          </XsParagraph>
        </Column>
      </Row>
    </Container>
  );
};

export default WorkWithUs;
