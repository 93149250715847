import styled from 'styled-components';
import tw from 'tailwind.macro';
import { math, rem } from 'polished';

export const Button = styled.button`
  ${tw`font-bold font-serif inline-block mb-24 relative text-sm text-purple-200 uppercase`};
  line-height: 1;

  &::after {
    ${tw`absolute bg-purple-200 left-0 w-full`};
    bottom: ${rem('-3px')};
    content: '';
    height: 2px;
  }

  &:focus {
    ${tw`outline-none`};
  }
`;

export const Navigation = styled.ul`
  ${tw`block flex flex-col pt-20 sticky`};
  top: 0;
`;

export const Title = styled.h2`
  ${tw`font-bold font-serif mb-32 pt-24 text-lg text-purple-200 uppercase`};
  ${props => props.marginBottom === '0' && tw`mb-0`}
  line-height: ${math('24/19')};
`;
